<template>
  <v-main>
    <v-container class='fill-height'>
      <v-layout row wrap justify-center align-center>
        <v-flex md5>
          <div class="text-center mt-16">
            <router-link to="/">
              <img
                src="../../static/assets/logo-light.svg"
                width="150px"
                alt=""
              />
            </router-link>
          </div>
          <v-card class="pa-12 auth-box mt-6 customized-form">
            <div class="text-center">
              <h4>Forgot Password</h4>
              <small
                >No worries, simply enter your email below to retrieve your
                password</small
              >
            </div>
            <v-form ref="login" class="mt-8" v-model="valid" lazy-validation>
              <p class="label">Your Email Address</p>
              <v-text-field
                v-model="form.email"
                @keypress.native.enter="$refs.login.validate() ? login() : null"
                required
                :rules="emailRules"
                outlined
              >
              </v-text-field>

              <v-btn
                block
                x-large
                class="primary auth-btn"
                :loading="loading"
                :style="{left: '50%', transform:'translateX(-50%)'}"
                @click="$refs.login.validate() ? forgotPassword() : null"
                >Retrieve Password</v-btn
              >
            </v-form>
            <div class="text-center mt-4">
              <small
                ><router-link to="/login"
                  >Remember Password?</router-link
                ></small
              ><br />
            </div>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog v-model="dialog" width="400">
      <v-card class="pa-6 form-colored-bg">
        <div class="text-center">
          <h4>Password Reset Token</h4>
          <p class="small mb-0 pb-0" style="font-size: 15px; line-height: 25px">
            Please enter the 4-digit Password Reset Token that was sent to your
            email.
          </p>
          <PincodeInput v-model="otp" class="my-12" :secure="true" />
        </div>

        <v-btn
          class="primary"
          block
          :loading="loading"
          @click="verifyOtp()"
          large
          >Verify OTP</v-btn
        >
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" width="400">
      <v-card class="pa-6 auth-box customized-form">
        <div class="text-center">
          <h4>Reset Your Password</h4>
          <p class="small mb-0 pb-0" style="font-size: 15px; line-height: 25px">
            You are almost there, please change your password below.
          </p>
        </div>
        <v-form lazy-validation v-model="valid" ref="form">
          <p class="label">Password</p>
          <v-text-field
            outlined
            placeholder="Enter password"
            v-model="form.password"
            type="password"
            required
            :rules="passwordRules"
          >
          </v-text-field>
          <p class="label">Confirm Password</p>
          <v-text-field
            outlined
            placeholder="Re-enter password"
            v-model="form.password_confirmation"
            type="password"
            required
            :rules="passwordRules2"
          >
          </v-text-field>
            <v-btn
                block
                x-large
                class="primary auth-btn"
                @click="$refs.form.validate() ? changePassword() : null"
                :loading="loading"
                >Reset Password</v-btn
              >
        </v-form>
      </v-card>
    </v-dialog>
  </v-main>
</template>
<script>
import PincodeInput from 'vue-pincode-input'
export default {
  layout: 'authentication',
  middleware: 'guest',
  components: {
    PincodeInput,
  },
  data() {
    return {
      valid: true,
      dialog: false,
      dialog2: false,
      loading: false,
      otp: '',
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        (v) => !!v || 'Password number is required',
        (v) => v.length >= 6 || 'Password must be at least six characters',
      ],
      passwordRules2: [
        (v) => !!v || 'Password Confirmation is required',
        (v) => v == this.form.password || 'The two paswords must match',
      ],
      form: {
        password: '',
      },
    }
  },
  methods: {
    async forgotPassword() {
      this.loading = true
      await this.$store
        .dispatch('auth/forgotpassword', this.form)
        .then((response) => {
          this.$toast.success(response.message)
          this.loading = false
          this.dialog = true
        })
        .catch((error) => {
          this.loading = false
          this.$toast.error(error.response.data.message)
        })
    },
    async verifyOtp() {
      this.loading = true
      const data = {
        otp: this.otp,
        email: this.form.email,
      }
      await this.$store
        .dispatch('auth/verifypasswordotp', data)
        .then((response) => {
          this.$toast.success(response.message)
          this.loading = false
          this.dialog = false
          this.dialog2 = true
        })
        .catch((error) => {
          this.loading = false
          this.$toast.error(error.response.data.message)
        })
    },
    async changePassword() {
      this.loading = true

      await this.$store
        .dispatch('auth/resetpassword', this.form)
        .then((response) => {
          this.$toast.success(response.message)
          this.loading = false
          this.dialog = false
          this.dialog2 = false
          this.$router.push('/login')
        })
        .catch((error) => {
          this.loading = false
          this.$toast.error(error.response.data.message)
        })
    },
  },

  mounted: function() {
      let elHtml = document.getElementsByTagName('html')[0]
      elHtml.style.overflowY = 'hidden'
    },
  destroyed: function() {
    let elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.overflowY = null
  }
}
</script>

<style lang="scss" scoped>
.auth-box {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  h4 {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 30px !important;
    line-height: 52px !important;
    color: #3a3838 !important;
  }
  small {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 27px !important;

    color: #3a3838 !important;
  }
  p.label {
    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 17px !important;
    line-height: 15px !important;
    color: #171b70 !important;
  }
}
.auth-btn {
  background: #171b70 !important;
  border: 0.5px solid #171b70 !important;
  box-shadow: 0px 1px 4px 1px rgba(66, 70, 149, 0.45) !important;
  border-radius: 5px !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 37px !important;
  text-transform: capitalize !important;
}
</style>
